export const environment = {
    production: true,
    name: 'prod',
    recaptcha: {
        siteKey: '6LcSaaMhAAAAACyA148w9tEB6uH8cnNbwJsXJkDF'
    },
    keyCloackUrl: 'https://login.lexnow.io/realms/lexnow-sso/protocol/openid-connect/auth',
    keyCloackClientId: 'lexnow-connect',
    frontDomain: 'https://www.lexnow.lu'
};
